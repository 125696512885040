import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-book-summary',
  templateUrl: './book-summary.component.html',
  styleUrls: ['./book-summary.component.scss']
})
export class BookSummaryComponent implements OnInit {

  @Input() individualBook: any;
  constructor() { }

  ngOnInit(): void {
  }

}
