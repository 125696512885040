export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDTTUx1aFP6OlYeq19EBYnzit1GgBrIHiM",
    authDomain: "cero-books-344c9.firebaseapp.com",
    databaseURL: "https://cero-books-344c9.firebaseio.com",
    projectId: "cero-books",
    storageBucket: "cero-books.appspot.com",
    messagingSenderId: "602967851392",
    appId: "1:602967851392:web:510f491d49442abb59c165",
    measurementId: "G-TFL1FMZ6H5"
  }
};
