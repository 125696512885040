import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  
  constructor(
    private firestore: AngularFirestore
  ) { }

  BOOK_COLLECTION : string = "books/enroll/list";

  readTrendingBooks() : Observable<DocumentChangeAction<any>[]> {
    return this.firestore.collection(this.BOOK_COLLECTION).snapshotChanges();
  }
}
