<nav class="navbar navbar-expand-sm navbar-dark bg-dark fixed-top">
    <div class="container">
        <a id="logo" routerLink="/home" class="navbar-brand logo">
            <img src="assets/img/base/koob_logo.png" alt="Cero Logo">
        </a>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li *ngIf="authService.getUser() as user">
                    <a routerLink="/dashboard" class="nav-link">
                        {{user.displayName}}</a>
                </li>
                <li><a routerLink="/home" class="nav-link">Home</a></li>
                <!-- <li><a routerLink="/team" class="nav-link">Team</a></li> -->
                <!-- <li><a routerLink="/opportunities" class="nav-link">Opportunities</a></li> -->
                <li *ngIf="authService.isLoggedIn">
                    <a class="nav-link" (click)="authService.SignOut()">
                        <i class="fas fa-sign-out-alt"></i>Log out
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>