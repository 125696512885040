<div class="row">
  <div class="col-lg-6">
    <img src="{{individualBook.thumbnailUrl}}" title="book preview"/>
  </div>
  <div class="col-lg-6">

    <div class="row">
      <h5><a href="/details/{{individualBook.id}}">{{individualBook.title}}</a></h5>
    </div>

    <div class="row">
      <!-- <p>Talking about this book? Be sure to tag it using #{{individualBook.title | removewhitespaces}} #koob</p> -->
    </div>
    <div class="row">
      <h6>Categories:</h6>
      <a *ngFor="let category of individualBook.categories" href="/categories/{{category}}"> {{category}}, </a>
    </div>
    <div class="row">
        <h6>Authors:</h6>
        <a *ngFor="let author of individualBook.authors" href="/authors/{{author}}"> {{author}}, </a>    
    </div>
  </div>
</div>