<div class="container">
  <section class="featured-books">
    <div class="center-block"><h1>Trending Books</h1></div>
    <div class="row">
      <div class="col-sm-12">
        <div *ngFor="let individualBook of bookList">
          <app-book-summary [individualBook]='individualBook'></app-book-summary>
          <!-- <app-book-details [individualBook]='individualBook'></app-book-details> -->
        </div>
      </div>
    </div>
  </section>
</div>