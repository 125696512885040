import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  bookList : any;
  constructor(public authService: AuthService,
    private dashboardService: DashboardService) { }

  ngOnInit() {
    this.dashboardService.readTrendingBooks().subscribe( data => {
        this.bookList = data;
        this.bookList = data.filter(e => {
          // if(e.payload.doc.data()['active']) {
          //   return true;
          // } 
          return true;
        }).map(e => {
          return {
            id: e.payload.doc.id,
            thumbnailUrl: e.payload.doc.data()['thumbnailUrl'],
            title: e.payload.doc.data()['title'],
            categories:  e.payload.doc.data()['categories'],
            authors:  e.payload.doc.data()['authors']
          };
      });
    });
  }

}
