<div class="row">
    <app-header></app-header>
</div>
<div class="row">
  <div class="col-sm-2"></div>
  <div class="col-sm-8">
      <router-outlet></router-outlet>
  </div>
  <div class="col-sm-2">
    <section class="featured-books">
      <div class="container">
          <div class="center-block"><h6>Trending Books</h6></div>
          <div class="row">
            <div class="col-sm-12">
              <div *ngFor="let individualBook of bookList">
                <app-book-summary [individualBook]='individualBook'></app-book-summary>
              </div>
            </div>
          </div>
      </div>
    </section>
  </div>
</div>
<div class="row">
<app-footer></app-footer>
</div>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

