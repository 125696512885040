<div class="container">
  <div class="row">
    <div class="logo">
      <a href="https://www.cerotechnologies.com" target="_blank">
        <img src="assets/img/base/cero.png" alt="Cero Technologies">
      </a>
    </div>
      <!-- Calling GoogleAuth Api from AuthService -->
      <div class="formGroup">
        <button type="button" class="btn googleBtn" (click)="authService.GoogleAuth()">
          <i class="fab fa-google-plus-g"></i>
          Log in with Cero Technologies Domain
        </button>
      </div>
  </div>
</div>
  